
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { ITaxonomyTerms } from '@kontent-ai/delivery-sdk';
import { getLocaleKeyFromValue } from 'helpers/getLocaleKey';
import { getTranslations } from 'helpers/translations';
import { ISR_REVALIDATE_TIME } from 'lib/constants';
import { GetStaticPaths, GetStaticProps, GetStaticPropsResult } from 'next';
import { Translate } from 'next-translate';
import ContentView, { _getDynamicPage, ContentViewProps } from 'pages/[...slug]';
import React from 'react';
import slugify from 'slugify';
import { getSubpageComponent, getSubpageProps } from 'utils/getOverviewPage';
type OverviewPageProps = {
    subpageType: string;
    pageProps: any;
    subcategoryKey: string | null;
    type: string | null;
};
export default function OverviewPage({ subpageType, pageProps, subcategoryKey, type, model, }: OverviewPageProps & ContentViewProps): JSX.Element {
    if (model)
        return <ContentView model={model}/>;
    const OverviewPageComponent = getSubpageComponent(subpageType, subcategoryKey ?? undefined, type ?? undefined);
    return <>{OverviewPageComponent && React.createElement(OverviewPageComponent as any, { ...pageProps })}</>;
}
export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
    const mainSubpage = ['career', 'contactUsUrl'];
    const getSubcategories = (category: string, translateFn: Record<string, Translate>) => {
        const subcategories = category.includes('career')
            ? ([{ codename: 'talentCommunitiesUrl' }] as ITaxonomyTerms[])
            : category.includes('contactUsUrl')
                ? ([{ codename: 'mapUrl' }, { codename: 'peopleUrl' }] as ITaxonomyTerms[])
                : null;
        return (subcategories &&
            subcategories.map((subcategory) => slugify(translateFn[category](subcategory.codename) as string, { lower: true })));
    };
    const paths = (await Promise.all(mainSubpage.flatMap((category) => locales!.map(async (locale) => {
        const { multiple, url } = await getTranslations(locale);
        const subcategories = await getSubcategories(category, {
            career: url,
            contactUsUrl: url,
        });
        return subcategories!.map((subcategory) => {
            return {
                params: {
                    mainSubpage: slugify(multiple(category), { lower: true }),
                    subcategory,
                },
                locale,
            };
        });
    })) ?? [])).flat();
    return {
        paths,
        fallback: 'blocking',
    };
};
const getStaticProps: GetStaticProps<OverviewPageProps | ContentViewProps> = async (ctx): Promise<GetStaticPropsResult<OverviewPageProps | ContentViewProps>> => {
    const reservedSubpageKeys = ['mapUrl', 'talentCommunitiesUrl', 'peopleUrl'];
    const languageCodename = ctx.locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
    const { mainSubpage, subcategory } = ctx.params as {
        mainSubpage: string;
        subcategory: string;
    };
    const subcategoryKey = await getLocaleKeyFromValue(languageCodename, 'url', subcategory).then((value) => value[0]);
    const isCodenamed = reservedSubpageKeys.includes(subcategoryKey);
    const subpageType = await getLocaleKeyFromValue(languageCodename, 'multiple', mainSubpage);
    const pageProps = await getSubpageProps(subpageType[0], languageCodename, isCodenamed ? subcategoryKey : subcategory, isCodenamed ? undefined : 'office');
    if (!pageProps) {
        return await _getDynamicPage(ctx);
    }
    return {
        props: {
            subpageType: subpageType[0],
            pageProps: pageProps,
            subcategoryKey: isCodenamed ? subcategoryKey : subcategory,
            type: isCodenamed ? null : 'office',
        },
        revalidate: ISR_REVALIDATE_TIME,
    };
};

    async function __Next_Translate__getStaticProps__191c15e740b__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[mainSubpage]/[subcategory]/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__191c15e740b__ as getStaticProps }
  